.sidenav {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}

.sidenav__buttons {
  display: flex;
  flex-direction: column;
}

.sidenav__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  background: 0;
  border: 0;
  margin: 5px 10px 5px 10px;
  padding: 10px 15px 10px 15px;
  border-radius: 15px;
  width: 100%;
  text-decoration: none;
}

.sidenav__button:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.128);
}

.sidenav__button > span {
  font-size: 18px;
  font-weight: bolder;
  margin-left: 10px;
}

.sidenav__more {
  position: fixed;
  bottom: 3px;
  width: 280px;
}

.logout__button {
  font-size: 15px;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  background-color: rgba(255, 255, 255, 0.211);
  border: 0;
  padding: 10px;
  margin-left: 10px;
}

.logout__button:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.424);
}

@media only screen and (max-width: 1000px) {
  /*collapses sidebar text at a certain threshold(around half of a full resolution page)*/
  .sidenav__buttons span {
    display: none;
  }

  .sidenav {
    width: 100px;
  }

  .sidenav__more {
    width: 100px;
  }
}

@media only screen and (max-width: 450px) {
  /*transfers the sidebar to the bottom for mobile interface*/
  .navbar-border {
    width: 101vw;
    height: 20vh;
    background-color: #313338;
    border-top: 1px solid #3a3a3a; /* Change the color and width as needed */
    transform: translate(-37.5%);
    position: absolute;
    z-index: auto;
   
    
  }

  .sidenav {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    width: 0.000001vw;
    padding-right: 100px;
  }

  .sidenav__buttons span {
    display: none;
  }

  .sidenav__buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 12vw;
    z-index: 2;
  }

  .sidenav__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    background: 0;
    border: 0;
    margin: 0 5px;
    padding: none;
    border-radius: 15px;
    width: auto;
    text-decoration: none;
    
  }

  .sidenav__button:hover {
    width: auto;
  }

  .sidenav__more {
    display: none;
  }
}