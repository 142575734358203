@font-face {
  font-family: 'Alata';
  src: url(/Fonts/Alata-Regular.ttf) format('truetype');
}

input::placeholder {
  color: white;
}

/* Ensure full opacity for all browsers */
input:-ms-input-placeholder {
  color: white;
}

input::-ms-input-placeholder {
  color: white;
}

.login {
  width: 75%; /*37.5vw*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  padding-bottom: 20px;
  margin-right: 30px;
  margin-left: 75px;
}

.login > img {
  margin-left: 27.5%;
  width: 45%; /*15vw*/
  height: 100%; /*15vh*/
  margin-right: auto;
  margin-bottom: 2.5vh;
  margin-top: 10%;
  max-width: 100%;
  max-height: 100%;
}


.login > input {
  width: 57%; /*20vw*/
  height: 2.5vh;
  background-color: #1e1f22;
  border: none;
  border-radius: 8px;
  padding-top: 12px;  /* Start with this value, and adjust accordingly */
  padding-right: 12px;
  padding-bottom: 12px;  /* Start with this value, and adjust accordingly */
  padding-left: 12px;
  margin-bottom: 2.5vh;
  color: #ffffff;
  font-family: 'Alata';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login > input:focus {
  outline: none;
}

.login > input::placeholder {
  line-height: 20px;
  color: #BCBAC1;
  font-family: 'Alata';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  
}

.login > button {
  width: 21.5vw; /*21.5vw*/
  height: 5.5vh;
  border-radius: 8px;
  border: 0;
  font-weight: bolder;
  background-color: #0095f6;
  color: #000;
  font-family: 'Alata';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login > button:hover {
  cursor: pointer;
}

.login > p {
  width: 343px;
  height: 66px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: 'Alata';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login > span {
  text-align: center;
  font-family: 'Alata';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-width: 1000px) {              
    .login > img {
      width: 34vw;
      height: 15vh;
      margin-top: 15vh;
      margin-left: 26%;
    }

    .login > input {
      margin-left: 0;
      width: 60vw;
    }

    .login > button {
      margin-left: 0;
      width: 63vw;
    }

    .login {
      width: 72.5vw;
    }
}

@media only screen and (max-width: 450px) {               /*for mobile*/
    .login > img {
      width: 65vw;
      height: 15vh;
      transform: translateX(-20%);
    }

    .login > input {
      margin-left: 0;
      width: 65vw;
    }

    .login > button {
      margin-left: 0;
      width: 72.5vw;
    }

    .login {
      width: 72.5vw;
    }
}