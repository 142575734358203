

  

.formInput{

    background-color: #ffffff;
    border: 1px solid grey;
    border-radius: 3px;
    padding: 12px;
    margin-bottom: 10px;
    color: black;
    
}

.new input {
    color: black;
}

.new button:disabled {
    background-color: greenyellow;
    cursor: not-allowed;
}

.left {
    max-width: 100px;
}