@font-face {
  font-family: "Alata";
  src: url(/Fonts/Alata-Regular.ttf) format("truetype");
}

.authenticate {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 50px 0px 50px;
}




.auth__left {
  flex: 0.5;
  text-align: center;
}

.auth__right {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.auth__left > img {
  width: 500px;
  height: 475px;
  margin-top: 15vh;
}

.auth__more {
  width: 37.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #000;
  font-family: "Alata";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 50px;
}

.auth__more > span {
  font-weight: 500;
  font-size: 14px;
  color: white;
}

.auth__more  button {
  background: 0;
  border: 0;
  font-weight: bolder;
  color: #0095f6;
}

.auth__more > span > button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
    .auth__left > img {
      display: none;
    }
}

@media only screen and (max-width: 450px) {
  /*for mobile*/
  .auth__left > img {
    display: none;
  }

  .auth__more {
    width: 75vw;
    margin-top: 0;
  }

  .auth__right {
    width: 100%;
  }
}
