.suggestions {
  margin-top: 30px;
  margin-right: 20px;
}

.suggestions__title {
  color: grey;
  font-weight: bold;
}

.username__left {
  display: inline-flex;
}

.username__info {
  display: flex;
  flex-direction: column;
  margin-left: 7px;
}

.relation {
  color: grey;
  font-size: 12px;
}

.suggestions__username {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.follow__button {
  color: #0095f6;
  font-weight: bold;
  background: 0;
  border: 0;
  margin-right: 5px;
}

.follow__button:hover {
  color: white;
  cursor: pointer;
}

.username {
  font-weight: 600;
  font-size: 14px;
}

@media only screen and (max-width: 1500px) {                /*shrinks the suggestions bar to fit the page when resolution width is smaller*/
    .suggestions {
      width: 22vw;
    }
    .username__info {
      margin-right: 25px;
    }
}
  
@media only screen and (max-width: 1000px) {                /*for mobile interface*/
    .suggestions, .suggestions__title, .username__left, .username__info, .relation, .suggestions__username, .follow__button, .follow__button:hover, .username {
      display: none;
    }
}