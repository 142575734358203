.timeline {
  display: flex;
  flex-direction: row;
}

.timeline__left {
  flex: 0.7;
  margin-top: -10px;
}

.timeline__right {
  flex: 0.3;
}

.timeline__header {
  margin: 0 auto;
  align-items: center;
  text-align: center;
}

.timeline__posts {
   width: 60vw; 
   
}

@media only screen and (max-width: 1500px) {                /*shrinks the suggestions bar to fit the page when resolution width is smaller*/
    .timeline__posts {
      width: 55vw;
      margin-left: 15vw;
    }


}
  
@media only screen and (max-width: 1000px) {               /*for mobile*/
    .timelime__posts {
      width: 80vw;
      margin-left: 0;
    }

    .timeline__header {
      transform: translateX(-15%);
      width: 60vw;
    }
}

@media (max-width: 450px) {
  .timeline__header {
    transform: translateX(-30%);
    width: 80vw;
  }

  .timeline__left {
    margin-bottom: 30px;
  }
}