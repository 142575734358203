.homepage {
  display: flex;
  flex-direction: row;
  margin-top: 80px;
}

.homepage__navWraper {
  position: relative;
  flex: 0.2;
}



.homepage__timeline {
  flex: 0.8;
}