html { 
  background-color: #313338;
  color: whitesmoke;
}

.loader-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.loading__quote {
  padding-top: 10px;
  color: grey;
  font-weight: 600;
  max-width: 300px;
  text-align: center;
}

.loader {
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-top: 6px solid #474747;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
