.signup {
  width: 75%; /*37.5vw*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  padding-bottom: 20px;
  margin-right: 30px;
  margin-left: 75px;
}

.signup > img {
  margin-left: 27.5%;
  width: 45%; /*15vw*/
  height: 100%; /*15vh*/
  margin-right: auto;
  margin-bottom: 2.5vh;
  margin-top: 10%;
  max-width: 100%;
  max-height: 100%;
}

.signup > input {
  width: 57%;
  height: 2.5vh;
  background-color: #1e1f22;
  border: none;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 2.5vh;
  color: #ffffff;
  font-family: 'Alata';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}



.signup > input::placeholder {
  color: #BCBAC1;
  font-family: 'Alata';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.signup > input:focus {
  outline: none;
}

.signup > button {
  width: 21.5vw; /*21.5vw*/
  height: 5.5vh;
  border-radius: 8px;
  border: 0;
  font-weight: bolder;
  background-color: #0095f6;
  color: #000;
  font-family: 'Alata';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.signup > button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {              
    .signup > img {
      width: 34vw;
      height: 15vh;
      margin-top: 15vh;
      margin-left: 26%;
    }

    .signup > input {
      margin-left: 0;
      width: 60vw;
    }

    .signup > button {
      margin-left: 0;
      width: 63vw;
    }

    .signup {
      width: 72.5vw;
    }
}

@media only screen and (max-width: 450px) {               /*for mobile*/
    .signup > img {
      width: 65vw;
      height: 15vh;
      transform: translateX(-20%);
    }
  
    .signup > input {
      margin-left: 0;
      width: 65vw;
    }
  
    .signup > button {
      margin-left: 0;
      width: 72.5vw;
    }
  
    .signup {
      width: 72.5vw;
      padding-top: 0px;
    }
}