.post {
  width: 25vw; /*75%*/
  margin: 10px auto 25px auto;
  padding: 20px;
  border-width: 4px;
  border-color: white;
  border-style: solid;
  border-radius: 25px;
  max-width: 450px;
}

.post__header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.post__headerAuthor {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bolder;
  vertical-align: auto;
}

.post__headerAuthor__name{
  display: inline-block;
    
}


.post__headerAuthor > span {
  color: grey;
  font-size: 15px;
  
}

.post__image > img {
  width: 100%;
  border-radius: 6px;
  border: 0.6px solid rgba(128, 128, 128, 0.516);
}

.post__image:hover {
  cursor: pointer;
}

.post__footerIcons {
  margin-top: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.postIcon {
  padding: 7px;
  font-size: 30px;
}

.postIcon:hover {
  cursor: pointer;
}

.post__image a {
  color: black;
  font-weight: 700;
  text-decoration: none;
  background-color: rgba(163, 198, 255, 0.75);
  border-radius: 10px;
  padding: 5px;
}
.likedBy {
  margin-bottom: 0.75rem;
  font-size: small;
  font-weight: 700;
}
.likedBy:hover {
  cursor: pointer;
}

.comment__input {
  padding: 0.1rem;
  flex: 1;
  border-radius: 10px;
  border: 0;
  background-color: transparent;
  color: white;
  font-size: 0.9rem;
}

.comment__input:focus {
  outline: none;
  box-shadow: none;
}

.comment__submit {
  font-weight: 700;
  color: grey;
  background-color: transparent;
  border-radius: 5px;
  border: 0;
  margin-left: 1rem;
}

.post button:enabled {
  color: white;
}

.post__comment {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.comment__left button {
  display: none;
}

.comment__left:hover button {
  display: block;
  border-radius: 5px;
  border-color: whitesmoke;
  color: whitesmoke;
  font-weight: 600;
  border-style: solid;
  background-color: transparent;
  margin-top: 0.5rem;
}

@media (max-width: 1000px) {
  .post {
    transform: translateX(-15%);
    width: 60vw;
  }
}

/* Media query for mobile screens */
@media (max-width: 450px) {
  .post {
    width: 75vw;
    padding: 18px;
    transform: translateX(-32.5%);
  }

  .post__header {
    flex-direction: column;
    align-items: flex-start;
  }

  .post__headerAuthor {
    margin-top: 10px;
  }

  .post__headerAuthor > span {
    margin-left: 0;
    font-size: 1em;
  }

  .post__image > img {
    border-radius: 3px;
  }

  h1 {
    font-size: 2.2em;
    font-weight: 650;
  }
}
