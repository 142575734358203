.navbar {
  box-sizing: border-box;
  position: fixed;
  z-index: 100;
  top: 0;
  width: 101vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #313338; /* Adjust the color as needed */
  color: black;
  padding: 0px;
  margin: 0px;
  border-bottom: 1px solid #3a3a3a;
}

.navbar__logo {
  width: 90px;
  padding: 20px 0px 5px 30px;
}

/* Add styles for left and right sections of the navbar */

.navbar__left {
  display: flex;
  justify-content: flex-start;
  flex: 1;
}

.navbar__right {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding: 0 20px;
  align-items: center;
}

.navbar__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: white;
  background: 0;
  border: 0;

  border-radius: 15px;
  width: auto;
  text-decoration: none;
  padding: 8px;
}

.navbar__button:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.128);
}

.navbar__button > span {
    margin-left: 8px;
    font-size: 15px;
    font-weight: bold;
    color: gray;
  }

  @media only screen and (max-width: 1000px) {
    /*collapses sidebar text at a certain threshold(around half of a full resolution page)*/
   

    .navbar__right span {
        display: none;
      }
  }
