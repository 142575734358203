.profiles {
  display: flex;
  flex-direction: row;
}

.profiles__left {
  margin-top: 25px;
  flex: 0.7;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
}

.profiles__right {
  flex: 0.3;
}

.profile {
  transform: translateX(35%);
  width: 75%;
  margin: 10px 10px 25px 20px;
  padding: 20px;
  border-width: 4px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  border-radius: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  max-width: 600px;
}
  #icon {
    vertical-align: middle;
    font-size: 30px;
  }

.profile__headerAuthor {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bolder;
}

.profile__right div {
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: 500;
}

.profile__cover {
  width: 24vw; /*width: 75%*/
  display: flex;
  background-color: rgb(255, 255, 255);
  color: black;
  border-radius: 35px;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 35px;
  font-size: 1em;
}

@media (max-width: 1500px) {
    .profile {
        transform: translateX(20%);
    }
}

@media (max-width: 450px) {
  .profile {
    transform: translateX(-2.5%);
    width: 82.5vw;
  }

  .profile__cover {
    width: 72.5vw;
    transform: translateX(-10%);
  }

  .profile__gallery {
    transform: translateX(22%);
  }


    .profiles {
      width: 150vw;
      transform: translateX(1.9%);
    }

}
